import React, {useEffect, useMemo, useState} from 'react';
import {useFetchTableData} from "../../hooks/useFetchTableData";
import {SearchInput} from "../../components/SearchInput";
import {DataTable} from "../../components/DataTable";
import {UsersService} from "../../services/users.service";
import {showObviousDate} from "../../shared/utils";
import {Space} from "antd";

export const Users = () => {
    const {
        data,
        total,
        loading,
        page,
        limit,
        query,
        handleSearchChange,
        handleTableChange,
    } = useFetchTableData(UsersService.getAll.bind(UsersService));

    const [invitedUsers, setInvitedUsers] = useState<Record<string, string>>({});

    useEffect(() => {
        // Получение данных о пригласивших
        const fetchInvitedUsers = async () => {
            const invitedUsers = data.filter((item: any) => item.invitedUserId).map((item: any) => item.invitedUserId);
            const userIds = [...new Set(invitedUsers)]; // Уникальные ID
            const userPromises = userIds.map((id) =>
                UsersService.getById(id).then((response) => ({
                    id,
                    fullName: response.fullName || response._id, // Предположим, что это поле с именем
                }))
            );
            const results = await Promise.all(userPromises);
            const userMap = results.reduce((acc, user) => {
                // @ts-ignore
                acc[user.id] = user.fullName;
                return acc;
            }, {});
            setInvitedUsers(userMap);
        };

        if (data.length > 0) {
            fetchInvitedUsers();
        }
    }, [data, setInvitedUsers]);

    const columns = useMemo(() =>[
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: 'ФИО',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Реф. код',
            dataIndex: 'referralCode',
            key: 'referralCode',
        },
        {
            title: 'Город',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'Кто пригласил',
            dataIndex: 'invitedUserId',
            key: 'invitedUserId',
            render: (id: string) => invitedUsers[id] || '',
        },
        {
            title: 'Дата регистрации',
            dataIndex: 'registerDate',
            key: 'registerDate',
            render: showObviousDate,
        },
    ], [invitedUsers]);

    return (
        <div>
            <Space direction="horizontal" style={{ marginBottom: 20 }}>
                <SearchInput value={query} onChange={handleSearchChange} />
            </Space>
            <DataTable
                data={data}
                columns={columns}
                loading={loading}
                total={total}
                page={page}
                limit={limit}
                onTableChange={handleTableChange}
            />
        </div>
    );
};